import { defineStore } from 'pinia';
import { reactive } from 'vue';

import { TPagination } from '@/types';
import { hasProperty } from '@/utils';

import {
  TPaginationPayload,
  TPaginationResetPayload,
  TPaginationStore,
  TPaginationStoreState,
} from './types';

export const usePaginationStore = defineStore('TMS_PAGINATION', (): TPaginationStore => {
  const generateInitialState = (): TPaginationStoreState => ({
    // только для грузовладельца
    orderListOnlyOrdersTable: null,
    executorsLookupTable: null,
    priceListTable: null,
    directionsTable: null,
    quotasTable: null,
    employeesTable: null,
    partnershipsTable: null,
    directionOrdersTable: null,
    matricesTable: null,
    shipmentsListTable: null,
    customReferencesTable: null,
    ordersFolders: {
      on_execution: null,
      finalized: null,
      pending: null,
      on_bidding: null,
      on_target_offering: null,
    },
    slotFeedsTable: null,
    // только для перевозчика
    vehiclesTable: null,
    runsTable: null,
    executorsLookupFeedTable: null,
    responsibleEmployeesTable: null,
    // общие
    executionRequestsTable: null,
    contractorPersonalAccountIncidentsTable: null,
    partnershipsPartnerContactsTable: null,
    selfBillingView: null,
    selfBillingListView: null,
    // лк контрагента, таблица рейсов
    contractorPersonalAccountPlannedRunsTable: null,
    contractorPersonalAccountCurrentRunsTable: null,
    contractorPersonalAccountFinishedRunsTable: null,
    // электронный документооборот
    operationalDocumentsListTable: null,
    // участники подбора
    executorsLookupParticipantsBidsTable: null,
    executorsLookupParticipantsVisitsTable: null,
    executorsLookupParticipantsUnvisitedTable: null,
    // согласования
    approvalsClaimsTable: null,
    approvalsSurchargesTable: null,
    // админка: входящие файлы
    incomingPayloadsTable: null,
    partnershipsPartnerContracts: null,
    // админка: список компаний
    tenantsAdminTable: null,
    partnershipContractsTable: null,
    partnershipInvitationsTable: null,
    partnershipResourcesVehicles: null,
    partnershipResourcesDrivers: null,
    tendersTable: null,
  });

  const state = reactive<TPaginationStoreState>(generateInitialState());

  const getPreparedPagination = (pagination: TPagination, resetOnlyCurrentPage: boolean): TPagination | null => (
    resetOnlyCurrentPage ? {
      ...pagination,
      currentPage: 1,
    } : null
  );

  const setTablePagination = ({
    folderName,
    tableName,
    pagination,
  }: TPaginationPayload) => {
    if (folderName) {
      if (!hasProperty(state, folderName)) {
        console.warn(`There is no folder with "${folderName}" name.`);
      } else {
        state[folderName][tableName] = pagination;
      }
    } else if (!hasProperty(state, tableName)) {
      console.warn(`There is no table with "${tableName}" name.`);
    } else {
      state[tableName] = pagination;
    }
  };

  const resetPagination = ({
    tableName,
    folderName,
    resetAllTablesInFolder,
    resetOnlyCurrentPage = true,
  }: TPaginationResetPayload) => {
    if (folderName && !hasProperty(state, folderName)) {
      console.warn(`There is no folder with "${folderName}" name.`);

      return;
    }

    if (!folderName && tableName && !hasProperty(state, tableName)) {
      console.warn(`There is no table with "${tableName}" name.`);

      return;
    }

    if (resetAllTablesInFolder && folderName) {
      Object.keys(state[folderName]).forEach((name: string) => {
        const currentPagination = state[folderName][name];

        if (currentPagination) {
          state[folderName][name] = getPreparedPagination(currentPagination, resetOnlyCurrentPage);
        }
      });
    } else if (folderName && tableName) {
      const currentPagination = state[folderName][tableName];

      if (currentPagination) {
        state[folderName][tableName] = getPreparedPagination(currentPagination, resetOnlyCurrentPage);
      }
    } else if (tableName) {
      const currentPagination = state[tableName];

      if (currentPagination) {
        state[tableName] = getPreparedPagination(currentPagination, resetOnlyCurrentPage);
      }
    }
  };

  const $reset = () => {
    const defaultState = generateInitialState();

    Object.keys(defaultState).forEach((key: string) => {
      state[key] = structuredClone(defaultState[key]);
    });
  };

  return {
    state,

    getPreparedPagination,
    setTablePagination,
    resetPagination,
    $reset,
  };
}, { persist: true });
