import { parseDateToAppFormat } from '@/utils/dateUtils';
import { TDateAppFormat } from '@/types';
import { DATE_FORMAT } from '@/constants';
import isString from '@/utils/isString';

/** Возвращает номер недели в году в ISO формате YYYY-wo. 2024-16 (16 неделя 2024 года) */
const getWeekOfYearFromDate = (value: TDateAppFormat | string): string | null => {
  if (!value) return null;

  if (isString(value)) {
    const date = parseDateToAppFormat(value, DATE_FORMAT.fullDate);

    return date.format(DATE_FORMAT.ISOWeekYear);
  }

  return value.format(DATE_FORMAT.ISOWeekYear);
};

export default getWeekOfYearFromDate;
