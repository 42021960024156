export enum EDigitalQueuePageType {
  booking = 'booking',
  schedule = 'schedule',
}

export enum EDigitalQueuePageView {
  timeline = 'timeline',
  table = 'table',
}

export enum EDigitalQueuePageTab {
  timeSlots = 'timeSlots',
  scheduleTemplates = 'scheduleTemplates',
}

export enum EReservedSlotType {
  locking = 'locking',
  reservation = 'reservation',
}

export enum ETimeSlotActionType {
  booking = 'booking',
  reservation = 'reservation',
  locking = 'locking',
}

export enum EDigitalQueueDomainErrors {
  weeklyScheduleHasReservedSlots = 'DomainErrors::YardManagementContext::WeeklyScheduleHasReservedSlots',
  weeklyScheduleExists = 'DomainErrors::YardManagementContext::WeeklyScheduleExists',
}

export enum EDigitalQueueDatePickerContext {
  timeline = 'timeline',
  table = 'table',
  schedule = 'schedule',
}

export const DIGITAL_QUEUE_VIEW_FILTER_SECTION = 'digitalQueueView';
export const DIGITAL_QUEUE_VIEW_BOOKINGS_TABLE_SUBSECTION = 'bookingsTable';
